.slide {
  .slide-img {
    max-width:20rem;
    max-heigth:20rem;
  }
  .slide-add-img {
    width:17rem;
    height:8rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
    padding: 2rem;
  }

}