body {
  margin: 0 !important;
}

.main-content {
    margin-left: 12rem;
    margin-top: 4rem;
    h1 {
        font-size: 2rem;
    }
}

.width-100 {
    width: 100%;
}

.display-flex {
    display: flex;
}
.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-left {
    justify-content: flex-start;
}
.flex-dir-column {
    flex-direction: column;
}

.flex-dir-row {
    flex-direction: row;
}

.feedback-snackbar {
    z-index: 9999999999 !important
}