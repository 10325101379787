.form-control {
    min-width: 150px !important;
    max-width: 200px;
    margin-bottom: 20px !important;
}
.bank-inner {
    max-width: 240px;
    .button {
        max-width:200px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    
}


.box-download {
    max-width: 500px;
    .btn-download {
        color: black;
        text-decoration: none;

    }
}