.login-wrapper {
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background: white;
  left: 0;
  top: 0;
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
  }
}
