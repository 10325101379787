.top-bar {
    position: fixed !important;
    z-index: 9999 !important;
    top: 0;
    left: 0;
    right: 0;
    .shop-name {
        text-transform: uppercase;
    }
    .logo {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: 80px;
        width: 85px;
        background-repeat: no-repeat;
        //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        .white-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 10px;
            background: white;}  
    }
}
.sidebar {
    .sidebar-list {
        margin-top: 4rem;
        .nav-link {
            color: black;
            display: block;
        }
        .active-link {
            background-color: #f5f4f4;
        }
    }

}